import React, { Children, Component } from 'react'
import { MDBCol, MDBRow, MDBCollapse, MDBContainer, MDBCard, MDBCardHeader, MDBCardBody, MDBIcon} from 'mdbreact';
import './whyMeItem.css'

class WhyMeItem extends Component {
    state = {
        collapseID: ""
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
      }

    render() {
    return (
        <MDBRow className={"py-5 mx-3 " + (this.props.odd ? "whymeodd" : "whymeeven")}>
              
                
                  <MDBCol lg="5" className={"" + (this.props.odd ? "order-1" : "order-lg-12")}>
                    <img className="img-fluid" src={this.props.image} alt="" />
                  </MDBCol>
                  <MDBCol lg="7" className="order-6 text-center text-lg-left p-1">
                    <h4>{this.props.title}</h4>
                    {this.props.children}
                  </MDBCol>
                
        </MDBRow>
        
        );
    }
}
      
export default WhyMeItem;


// const IconBullet = ( props ) => {
//     return (
//         <MDBRow className="py-1">
//             <MDBCol size="2">
//             <MDBIcon far={props.far} fab={props.fab} icon={props.icon} size="2x" className="cyan-text pr-3"/>
//             </MDBCol>
//             <MDBCol size="10">
//             <p>{props.children}</p>
            
//             </MDBCol>
//         </MDBRow>
//     )
// }

// export default IconBullet