import React, { Component } from 'react';
import Layout from '../components/layout';
import Intro from '../components/mask';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import IconBullet from '../components/iconbullet'
import WhyMeItem from '../components/whyMeItem'
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBBtn} from 'mdbreact'
import wd_badge from "../images/badge.png"

class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `MDBReact`, `react`, `Material Design For Bootstrap`]} />
        
        <main>
          
          <section id="faq">
                    
            <WhyMeItem odd title="Engaging and Personalised Ceremonies" image="https://www.audiobuzz.org/images/5J0A9242f-2-copy-2.jpg?width=600">
              <p>
                I believe your wedding ceremony should be the most enjoyable, moving and memorable part of your day.  Unfortunately though many ceremonies are less than inspiring for the guests.  If you appoint me as your celebrant I will take you and the audience on an engaging journey celebrating your past, present and future as you make the commitment of marriage.  I will ensure that your ceremony is fun, personal and meaningful and has the right balance of heartfelt sentiment, humour and special moments.  The best part of Australian marriage law is that you have complete freedom to celebrate your marriage in whatever form you like.  Other than a few compulsory words you are entitled to do everything exactly as you wish with no rules around rituals, traditions or roles.  Together with you I will create a ceremony that includes the ‘oooh aaah’, the ‘ha ha’, and the ‘whah whah’ (happy tears of course!) that will have you and your guests talking for years to come.
              </p>
            </WhyMeItem>

            <WhyMeItem even title="Support, Support and more Support" image="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%206.jpg?width=600">
              <p>
                Weddings can be stressful but they don’t need to be.  With the right professionals supporting you in both the planning and execution stages you really will have nothing to worry about.  People who know me describe me as the most organised and detail oriented person they know.  I love to plan, almost as much as I love to facilitate an event.  I see my role in your wedding being predominately about supporting you to craft and deliver a really special and memorable ceremony, and that means I need to be accessible, responsive, a great listener, across the legal stuff, well resourced, calm, dependable and super excited about being included in your special day. Getting to know you through phone, email and face-to-face contact is how I craft a really special and memorable ceremony that is totally personalised.  Book me and you are welcome to contact me as often as you need to ask whatever you like.  It is my job to support and guide you throughout the planning process.  I actually enjoy over servicing people (is that even a thing in a service industry?). 
              </p>
            </WhyMeItem>

            <WhyMeItem odd title="The Right Celebrant For You" image="https://www.audiobuzz.org/images/Amanda Expo photos 15.jpg?width=600">
              <p>
                I know how important your wedding day is to you.  It is a unique and one off celebration of your marriage, and for that reason it is so important to engage the right person for the role. You would not pick your dress just because it was the cheapest on the rack, so I urge you to place the same emphasis on choosing a celebrant that you gel with, who matches your personality, and someone who truly understands your vision for your ceremony and has the skills to bring this vision to life.
              </p>
              <p>
                Engage me as your celebrant and relax in the knowledge you are hiring a seasoned professional who has over 20 years in speech writing and public speaking, as well as event planning, coordination and facilitation. So you can rest, safe in the knowledge that you wont have to worry about what you should be saying and doing on the day.
              </p>
            </WhyMeItem>

           

            
          </section>
          
        </main>
        </Layout>
      </>
    );
  }
}

export default App;
